.universalPadding {
  padding: 0 5%;
}

@media screen and (max-width: 1800px) {
  .universalPadding {
    padding: 1% 5%;
  }
}

@media screen and (max-width: 1300px) {
  .universalPadding {
    padding: 2% 5%;
  }
}
