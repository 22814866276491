.container {
  display: flex;
  justify-content: space-between;
  padding: 2% 0%;
}

.logo {
  color: #65ca73;
  font-size: 2.25rem;
  text-decoration: none;
  font-weight: 600;
}

.linksContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.outDisplay {
  display: none;
}

.inDisplay {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid black;
  padding-bottom: 30px;
  width: 100%;
  margin-top: 2%;
  animation: slideIn 0.5s ease-out;
  animation-fill-mode: forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    height: 0;
  }
  to {
    opacity: 1;
    height: 30vh;
  }
}

.link {
  font-size: 1.2rem;
  margin: 0px 12px;
  color: #383a2a;
  text-decoration: none;
}

.link:hover {
  color: #65ca73;
  transition: 0.2s;
}

.upperRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.burgerMenu:hover {
  cursor: pointer;
}

@media screen and (max-width: 825px) {
  .link {
    margin: 12px 0px;
    font-size: 1.5rem;
  }

  .burger-menu {
    display: block;
    cursor: pointer;
  }

  .burgerMenu:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  .bar {
    width: 30px;
    height: 3px;
    background-color: #333;
    margin: 5px 0;
    transition: 0.4s;
  }

  .change .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-6.5px, 6px);
  }

  .change .bar:nth-child(2) {
    opacity: 0;
  }

  .change .bar:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -5px);
  }

  .container {
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
}
