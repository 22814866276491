.lessonsContainer {
    margin-top: 2%;
}

.privateTitle {
    text-align: center;
    font-size: 2.25rem;
    font-weight: 600;
    color: #65ca73;
}

.groupTitle {
    text-align: center;
    font-size: 2.25rem;
    font-weight: 600;
    color: #65ca73;
    margin-top: 4%;
}

.privateLessonsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.image {
    max-width: 50%;
    border-radius: 20px;
}

.privateLessonsFormContainer {
    width: 100%;
    margin-left: 2%;
}

@media screen and (max-width: 1000px) {
    .image {
        display: none;
    }
    .lessonsContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .groupTitle,
    .privateTitle {
        margin-top: 2%;
        text-align: center;
    }
}

@media screen and (max-width: 900px) {
    .groupTitle {
        margin-top: 6%;
    }
}
