.container {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    gap: 3rem;
    padding: 5px 10px 15px 5px;
    justify-content: center;
}

.item {
    flex-shrink: 0;
    width: 325px;
}

@media only screen and (max-width: 1666px) {
    .container {
        overflow-x: scroll;
        justify-content: stretch;
        gap: 1.5rem;
    }

    ::-webkit-scrollbar {
        width: 4px !important;
        height: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1) !important;
        -webkit-border-radius: 10px !important;
        color: rgb(101, 202, 115);
        border-radius: 10px !important;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px !important;
        border-radius: 10px !important;
        background: #41617d !important;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1) !important;
    }

    ::-webkit-scrollbar-thumb:window-inactive {
        background: #41617d !important;
    }
}
