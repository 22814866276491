.title {
    text-align: center;
    color: #060747;
}

.courses {
    margin-top: 3rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    gap: 6rem;
    box-sizing: border-box;
    padding: 0 10%;
}

.course {
    text-align: center;
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 1300px) {
    .courses {
        padding: 0 5%;
    }
}

@media only screen and (max-width: 860px) {
    .courses {
        grid-template-columns: 1fr;
        padding: 0;
    }

    .course {
        width: 70%;
    }
}

@media only screen and (max-width: 550px) {
    .courses {
        gap: 4rem;
    }

    .course {
        width: 90%;
    }
}
