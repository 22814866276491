.reviewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.reviewTitle {
  color: #65ca73;
  font-size: 2.25rem;
  font-weight: 600;
}

.review {
  font-style: italic;
  line-height: 32px;
  max-width: 800px;
}

.parent {
}
