.container {
    width: 325px;
    padding: 15px;
    box-shadow: 1px 2px 5px 2px rgba(159, 159, 159, 0.2);
    border-radius: 8px;
    transition: all 0.2s;
    color: black;
    text-decoration: none;
}

.bigContainer {
    padding: 15px;
    box-shadow: 1px 2px 5px 2px rgba(159, 159, 159, 0.2);
    border-radius: 8px;
    transition: all 0.2s;
    color: black;
    text-decoration: none;
}

.bigContainer > .title {
    margin-top: 1rem;
}

.bigContainer > .description {
    margin-top: 1.5rem;
}

.container:hover,
.bigContainer:hover {
    cursor: pointer;
    box-shadow: 1px 2px 10px 4px rgba(123, 123, 123, 0.2);
}

.image {
    width: 100%;
    aspect-ratio: 1.83;
    object-fit: cover;
    border-radius: 8px;
}

.title {
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 0.3rem;
    margin-bottom: 0.5rem;
}

.description {
    margin-top: auto;
}

.info {
    margin-top: 0;
    margin-bottom: 0.3rem;
}

.green {
    color: #65ca73;
}
